import React from 'react'
import styled from '@emotion/styled'
import { SketchPicker } from 'react-color'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const handleColorPickerChange = (index, color, activeTheme, setTheme) => {
  // use hex if the color is solid, else use rgba() so we can include the alpha channel.
  const value = color.rgb.a === 1
    ? color.hex
    : `rgba(${ color.rgb.r }, ${ color.rgb.g }, ${ color.rgb.b }, ${ color.rgb.a })`
  const updatedTheme = activeTheme.map((themeValue, i) => {
    if (i !== index) {
      return themeValue
    }
    return value
  })

  setTheme(updatedTheme)
}

const ColorEditor = ({ setting, index, activeTheme, setTheme, setActiveEditor }) => (
  <Wrapper>
    <Editor>
      <CloseButton onClick={() => setActiveEditor(-1)} icon={faTimesCircle} size="1x" />
      <SketchPicker color={setting} onChange={color => handleColorPickerChange(index, color, activeTheme, setTheme)}/>
    </Editor>
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
`

const Editor = styled.div`
  position: absolute;
  top: -42px;
  left: 0px;
`

const CloseButton = styled(FontAwesomeIcon)`
  position: relative;
  right: -117px;
  top: 8px;
  z-index: 1;
  color: var(--primary-text-color);
  cursor: pointer;
`

export default ColorEditor
