export const themes = {
  'Dark Theme v1': [
    '#dedede',
    '#b8b8b8',
    '#fafafa',
    '#dedede',
    '#dedede',
    '#02111D',
    '#6c6ca3',
    'rgb(88, 110, 128, 0.45)',
    '#dedede',
    '#dedede',
    '#142534',
    '#6c6ca3',
    '##f8f8f8',
    'rgba(255, 255, 255, 0.15)'
  ],
  'Default Theme': [
    '#666',
    '#777',
    '#333',
    '#333',
    '#f8f8f8',
    '#fff',
    '#0d6186',
    'rgba(13, 97, 134, 0.45)',
    '#b3b3b3',
    '#ddd',
    '#fbfaf8',
    '#c25',
    '#000',
    'rgba(0, 0, 0, 0.15)'
  ],
  'Dark Theme v2': [
    '#dedede',
    '#b8b8b8',
    '#fafafa',
    '#dedede',
    '#dedede',
    '#02111D',
    '#ff3366',
    'rgb(255,51,102, 0.45)',
    '#b3b3b3',
    '#ddd',
    '#142534',
    '#ff3366',
    '#f8f8f8',
    'rgba(255, 255, 255, 0.15)'
  ],
  'Green Theme': [
    '#666',
    '#777',
    '#333',
    '#333',
    '#f8f8f8',
    '#fff',
    '#038c36',
    'rgb(3, 140, 54, 0.45)',
    '#b3b3b3',
    '#ddd',
    '#fbfaf8',
    '#038c36',
    '#000',
    'rgba(0, 0, 0, 0.15)',
  ],
  'Orange Theme': [
    '#666',
    '#777',
    '#333',
    '#333',
    '#f8f8f8',
    '#fff',
    '#ff9900',
    'rgb(255, 127, 0, 0.45)',
    '#b3b3b3',
    '#ddd',
    '#fbfaf8',
    '#ff9900',
    '#000',
    'rgba(0, 0, 0, 0.15)',
  ]
}

export const cssVarList = [
  '--primary-text-color',
  '--secondary-text-color',
  '--heading-text-color',
  '--link-text-color',
  '--inverted-text-color',
  '--body-bg-color',
  '--highlight-color-dark',
  '--highlight-color-light',
  '--footer-text-color',
  '--footer-border-color',
  '--code-background-color',
  '--code-inline-color',
  '--code-block-color',
  '--code-border-color'
]
