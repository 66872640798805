import React from 'react'

const months = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

const renderDate = date => (
  <span>
    {`${ date.getDate() }`.padStart(2, '0') + ' ' + months[date.getMonth()] + ' ' + (date.getYear() + 1900)}
  </span>
)

export default renderDate
