import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { themes, cssVarList } from './themes'
import ColorEditor from './colorEditor'
import ThemeButton from './themeButton'

const prettyLabelName = label => label.substring(2).replace(/-/g, ' ')

/**
 * Sets new values for all CSS variables the sites theme uses.
 */
const updateCssVars = theme => {
  const body = document.documentElement
  cssVarList.forEach((variable, i) => {
    body.style.setProperty(variable, theme[i])
  })
}

export const ThemeChanger = () => {
  // hook that stores the active theme
  const [activeTheme, setActiveTheme] = useState(themes['Default Theme'])
  // hook that stores the active edit pane
  const [activeEditor, setActiveEditor] = useState(-1)

  const changeTheme = theme => {
    setActiveTheme(theme)
    updateCssVars(theme)
  }

  const toggleEditor = index => {
    setActiveEditor(activeEditor === index ? -1 : index)
  }

  return (
    <Wrapper>
      <h2>Theme Switcher</h2>
      <p>
        Select theme an existing theme or create your own!
      </p>
      <ThemeButton themeName="Dark Theme v1" setTheme={changeTheme} />
      <ThemeButton themeName="Dark Theme v2" setTheme={changeTheme} />
      <ThemeButton themeName="Default Theme" setTheme={changeTheme} />
      <ThemeButton themeName="Green Theme" setTheme={changeTheme} />
      <ThemeButton themeName="Orange Theme" setTheme={changeTheme} />
      <div>
        <h2>Custom Theme Builder</h2>
        {activeTheme.map((setting, i) => (
          <StyleSettingContainer key={i}>
            <StyledLabel>{prettyLabelName(cssVarList[i])}</StyledLabel>
            <StyledInput onClick={() => toggleEditor(i)} readOnly type="text" value={setting} />
            <EditIcon open={i === activeEditor} onClick={() => toggleEditor(i)} icon={faEdit} size="1x" />
            {activeEditor === i ? (
              <ColorEditor
                setting={setting}
                index={i}
                setActiveEditor={setActiveEditor}
                setTheme={changeTheme}
                activeTheme={activeTheme}
              />
            ) : null}
          </StyleSettingContainer>
        ))}
      </div>
    </Wrapper>
  )
}

const StyleSettingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
`

const StyledLabel = styled.label`
  text-transform: capitalize;
  width: 200px;
  text-align: right;
  margin-right: 6px;
`

const StyledInput = styled.input`
  width: 200px;
  border-radius: 6px;
  padding: 5px;
  border: 1px solid #bebebe;
`

const EditIcon = styled(FontAwesomeIcon)`
  position: relative;
  left: -25px;
  color: var(${ ({ open }) => open ? '--highlight-color-dark' : '--highlight-color-light' });
  cursor: pointer;

  &:hover {
    color: var(--highlight-color-dark);
  }
`

const Wrapper = styled.div`
  text-align: center;
  padding: 16px 0;
`
