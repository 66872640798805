import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const Tag = ({ tag }) => (
  <StyledTag key={tag}>
    <Link to={`/tag/${ tag }`}>{tag}</Link>
  </StyledTag>
)

const StyledTag = styled.span`
  display: inline-block;
  text-transform: uppercase;
  margin: 0 4px;
  font-size: .65rem;
  transition: all 350ms;
  position: relative;
  top: -1px;

  a {
    color: var(--inverted-text-color);
    text-decoration: none;
    padding: 3px 5px;
    border-radius: .25rem;
    background-color: var(--highlight-color-light);
    transition: all .5s;
    white-space: nowrap;

    &:hover {
      background-color: var(--highlight-color-dark);
    }
  }
`

export default Tag
