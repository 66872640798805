import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const PostLink = ({ children, to, ...props }) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <InternalLink to={to} {...props}>
        {children}
      </InternalLink>
    )
  }
  return (
    <ExternalLink href={to} {...props}>
      {children}
    </ExternalLink>
  )
}

const ExternalLink = styled.a`
  border-bottom: 1px dashed var(--highlight-color-dark);
  border-bottom-color: var(--highlight-color-dark);
  line-height: 25px;
  transition: all .5s;
  color: var(--link-text-color);
  text-decoration: none;

  &:hover {
    color: var(--inverted-text-color);
    border-color: var(--highlight-color-dark);
    background: var(--highlight-color-dark);
    transition: all .5s;
  }
`

const InternalLink = styled(Link)`
  border-bottom: 1px dashed var(--highlight-color-dark);
  border-bottom-color: var(--highlight-color-dark);
  line-height: 25px;
  transition: all .5s;
  color: var(--link-text-color);
  text-decoration: none;

  &:hover {
    color: var(--inverted-text-color);
    border-color: var(--highlight-color-dark);
    background: var(--highlight-color-dark);
    transition: all .5s;
  }
`

export default PostLink
