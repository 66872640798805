import React from 'react'
import Markdown from 'markdown-to-jsx'
import styled from '@emotion/styled'
import Image from '../components/postImage'
import ExpoSnack from 'react-expo-snack'
import { Tweet } from 'react-twitter-widgets'
import Gist from 'react-gist'

import PostLink from './postLink'

// post imports
import { ThemeChanger } from './postComponents/cssVars/themeChanger'

const ScriptHandler = props => {
  if (props.src && props.src.includes('gist.github.com')) {
    return <Gist id={props.src.split('/')[4].split('.')[0]}/>
  }
  return null
}

const DivHandler = ({ children, ...props }) => {
  if (props['data-snack-id']) {
    return <ExpoSnack id={props['data-snack-id']} />
  }
  return <div {...props}>{children}</div>
}

const AHandler = ({ children, href, ...props }) => {
  return <PostLink to={href} {...props}>{children}</PostLink>
}

export default ({ markdown, postImages }) => {
  const ImageHandler = ({ src, ...props }) => {
    if (src.includes('//')) {
      // eslint-disable-next-line jsx-a11y/alt-text
      return <PostExternalImage src={src} {...props} />
    }
    return <Image src={src} postImages={postImages} />
  }

  return (
    <Markdown options={{
      overrides: {
        script: {
          component: ScriptHandler
        },
        img: {
          component: ImageHandler
        },
        div: {
          component: DivHandler
        },
        a: {
          component: AHandler
        },
        // Features
        Gist: Gist,
        ExpoSnack: ExpoSnack,
        Tweet: Tweet,
        // Post specific controls
        ThemeChanger: ThemeChanger // styling-with-css-vars.md
      },
    }}
    >
      {markdown}
    </Markdown>
  )
}

const PostExternalImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
`
