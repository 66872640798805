import React from 'react'
import styled from '@emotion/styled'
import { themes } from './themes'

const ThemeButton = ({ themeName, setTheme }) => {
  const selectedTheme = themes[themeName]
  return (
    <StyledThemeButton
      type="button"
      color={selectedTheme[6]}
      onClick={() => setTheme(selectedTheme)}
      value={themeName}
    />
  )
}

const StyledThemeButton = styled.input`
  background-color: ${ ({ color }) => color };
  border: 1px solid ${ ({ color }) => color };
  color: #f8f8f8;
  margin: 2px 5px;
  height: 32px;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
    color: ${ ({ color }) => color };
    background-color: #f8f8f8;
  }
`

export default ThemeButton
