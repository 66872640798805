import React from 'react'
import styled from '@emotion/styled'
import { DiscussionEmbed } from 'disqus-react'
import { Link, graphql } from 'gatsby'

import renderDate from '../components/date'
import SEO from '../components/seo'
import Image from '../components/postImage'
import Tag from '../components/tag'
import PostBody from '../components/postBody'

export default ({ pageContext, data }) => {
  const disqusShortname = 'broonix-rants'
  const { post, prevPost, nextPost } = pageContext
  const markdown = post.markdown
  const postImages = data.allImageSharp ? data.allImageSharp.edges : []

  return (
    <React.Fragment>
      {post.tags && (
        <SEO title={post.title} keywords={post.tags.map(tag => tag.name)} />
      )}
      <PostHeader>
        <div>
          {renderDate(new Date(post.published_at))}{' '}
          in{' '}
          {post.tags && post.tags.map(
            tag => <Tag key={tag} tag={tag} />
          )}
        </div>
        {post.image && (
          <PostImage>
            <Image src={post.image} postImages={postImages}/>
          </PostImage>
        )}
        <h1> {post.title} </h1>
      </PostHeader>
      <PostBody markdown={markdown} postImages={postImages}/>
      <PostFooter>
        <div>
          { prevPost && (
            <Link to={prevPost.slug}>← {prevPost.title}</Link>
          )}
        </div>
        <div>
          { nextPost && (
            <Link to={nextPost.slug}>{nextPost.title}  →</Link>
          )}
        </div>
      </PostFooter>
      <DiscussionEmbed shortname={disqusShortname} config={{}} />
    </React.Fragment>
  )
}

export const query = graphql`
  query PostImageQuery($images: [String]) {
    allImageSharp(filter: { fluid: { originalName: { in: $images } } }) {
      edges {
        node {
          fluid(maxWidth: 920) {
            src,
            srcSet,
            sizes,
            base64,
            aspectRatio,
            originalName,
            presentationWidth
          }
        }
      }
    }
  }
`

const PostFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  a {
    font-size: .8em;
    border: 1px solid var(--highlight-color-dark);
    color: var(--highlight-color-dark);
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 4px 8px;
    text-decoration: none;
  }
`

const PostHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .85rem;
  color: var(--footer-text-color);
  text-align: center;
`

const PostImage = styled.div`
  margin-top: 2.6rem;
  height: auto;
  width: 100%;
`
