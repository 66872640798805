import React from 'react'
import Img from 'gatsby-image'

const postImage = props => {
  const { src, postImages } = props
  const imageFluid = postImages.find(
    image => src.includes(image.node.fluid.originalName)
  )
  if (!imageFluid) {
    return null
  }
  return (
    <Img
      fluid={imageFluid.node.fluid}
      imgStyle={{
        maxWidth: `${ imageFluid.node.fluid.presentationWidth }px`,
        height: 'auto'
      }}
      style={{
        maxWidth: `${ imageFluid.node.fluid.presentationWidth }px`,
        margin: '0 auto'
      }}
    />
  )
}

export default postImage
